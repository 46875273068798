.App {
  display: flex;
  column-gap: 25px;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.create-post {
  height: 100%;
  min-width: 300px;
  margin-left: 20px;
}

.create-post-title {
  margin-top: 20px;
  font-weight: bold;
}

.input-label {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

textarea.content-input {
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  resize: vertical;
}

.posts {
  height: 100vh;
  width: 100%;
  max-width: 800px;
  overflow-y: auto;
}

.post-title {
  margin-bottom: 5px;
}

.post-author {
  font-style: italic;
  word-break: break-all;
}

.post-content {
  margin-top: 8px;
}
